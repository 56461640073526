/* globals STATIC_URL */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { LoadingSpinner, useSafeIntl } from 'bluesquare-components';
import { IconButton, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { LogoutButton } from 'Iaso/components/nav/LogoutButton';
import { useCurrentUser } from 'Iaso/utils/usersUtils.ts';
import { useSidebar } from 'Iaso/domains/app/contexts/SideBarContext';
import { userHasPermission } from 'Iaso/domains/users/utils';

import { homeActions } from '../redux/home';
import HomeMap from '../components/HomeMap';
import HomeBarChart from '../components/HomeBarChart';

import '../../../../../css/_landing-page.scss';

const useStyles = makeStyles(theme => ({
    topMenu: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    container: {
        width: '100%',
        height: '100vh',
        margin: 0,
        overflow: 'auto',
    },
}));

const Home = props => {
    const currentUser = useCurrentUser();
    const { formatMessage } = useSafeIntl();
    const classes = useStyles();

    const { toggleSidebar } = useSidebar();

    useEffect(() => {
        props.fetchHomeDatas();
    }, []);

    const { geoZones, geoProvinces, zones, barChartDatas } = props;

    return (
        <>
            <Box className={classes.container} id="trypelim-home">
                <Grid className={classes.topMenu} container spacing={2}>
                    <Grid container item xs={6} justifyContent="flex-start">
                        <Box m={2}>
                            <IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={toggleSidebar}
                                id="menu-button"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <Box p={4} display="flex" alignItems="center">
                            <Box pl={2}>
                                <LogoutButton color="primary" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <section className="home-container">
                    <section className="section--feature--welcome">
                        <div className="section__content">
                            <div className="section__content--welcome">
                                <h1>
                                    <FormattedMessage
                                        id="home.title"
                                        defaultMessage="Bienvenue au tableau de bord de Trypelim"
                                    />
                                </h1>
                                <div className="welcome__images">
                                    <img
                                        src={`${STATIC_URL}images/drc-flag.png`}
                                        alt="DRC flag"
                                    />
                                    <img
                                        src={`${STATIC_URL}images/pnltha-logo.png`}
                                        alt="PNLTHA logo"
                                    />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="home.titleText"
                                        defaultMessage="Republique Democratique du Congo, Ministere de la Santé Publique, Programme National de Lutte contre la THA (PNLTHA)."
                                    />
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="section--feature--pilot-area">
                        <div className="section__content--pilot-area">
                            <h2>
                                <FormattedMessage
                                    id="home.subTitle"
                                    defaultMessage="Mission du Programme"
                                />
                            </h2>
                            <p>
                                <FormattedMessage
                                    id="home.text"
                                    defaultMessage="Coordonner et Organiser la lutte contre la THA en R.D.Congo"
                                />
                            </p>
                            <h2>
                                <FormattedMessage
                                    id="home.subTitle2"
                                    defaultMessage="Objectif du Programme"
                                />
                            </h2>
                            <p>
                                <FormattedMessage
                                    id="home.text2"
                                    defaultMessage="Réduire la morbidité et la mortalité due au THA à un niveau compatible avec une vie productive normale pour les habitants des zones où la maladie est endémique (1 cas par 10000 habitants)"
                                />
                            </p>
                            <h2>
                                <FormattedMessage
                                    id="home.subTitle3"
                                    defaultMessage="Organisation du Programme"
                                />
                            </h2>
                            <p>
                                <strong>
                                    <FormattedMessage
                                        id="home.text3Strong"
                                        defaultMessage="Niveau Central"
                                    />
                                    :{' '}
                                </strong>
                                <FormattedMessage
                                    id="home.text3"
                                    defaultMessage="Normatif et élaborer des stratégies et appui technique au niveau provincial"
                                />
                                :
                            </p>
                            <p>
                                <strong>
                                    <FormattedMessage
                                        id="home.text4Strong"
                                        defaultMessage="Niveau Provincial"
                                    />
                                    :{' '}
                                </strong>
                                <FormattedMessage
                                    id="home.text4"
                                    defaultMessage="Organiser et Coordonner la lutte dans la province. Fournir un appui technique de base et des conseils aux médecins provinciaux"
                                />
                                :
                            </p>
                            <p>
                                <strong>
                                    <FormattedMessage
                                        id="home.text5Strong"
                                        defaultMessage="Niveau Périphérique Opérationnel"
                                    />
                                    :{' '}
                                </strong>
                                <FormattedMessage
                                    id="home.text5"
                                    defaultMessage="Exécution de la lutte"
                                />
                            </p>
                            {userHasPermission(
                                'iaso_trypelim_plannings_microplanning',
                                currentUser,
                            ) && (
                                <a
                                    href="/old-trypelim-dashboard/plannings/micro/"
                                    className="button--success"
                                >
                                    <FormattedMessage
                                        id="home.microplanningLink"
                                        defaultMessage="Utiliser l'outil de reprogrammation"
                                    />
                                    <i className="fa fa-arrow-right icon--right" />
                                </a>
                            )}
                        </div>
                        <div
                            className="section__content__image--pilot-area"
                            id="home-map"
                        >
                            {geoZones && geoProvinces && (
                                <HomeMap
                                    overlays={{ labels: false }}
                                    geoZones={geoZones}
                                    geoProvinces={geoProvinces}
                                    zones={zones}
                                />
                            )}
                            {(!geoZones || !geoProvinces) && (
                                <LoadingSpinner
                                    message={formatMessage({
                                        defaultMessage: 'Loading',
                                        id: 'main.label.loading',
                                    })}
                                />
                            )}
                        </div>
                    </section>
                    <section className="section--feature--bar-chart">
                        <h2>
                            {formatMessage({
                                defaultMessage: 'Cas positifs par année',
                                id: 'home.barchart.title',
                            })}
                        </h2>
                        {barChartDatas.length > 0 && (
                            <HomeBarChart showLegend datas={barChartDatas} />
                        )}
                        {barChartDatas.length === 0 && (
                            <LoadingSpinner
                                message={formatMessage({
                                    defaultMessage: 'Loading',
                                    id: 'main.label.loading',
                                })}
                            />
                        )}
                    </section>
                    <section className="section--feature--reports">
                        <div className="section__content__image--reports">
                            <img
                                src={`${STATIC_URL}images/monthly-report.png`}
                                alt="Monthly report example"
                            />
                        </div>
                        <div className="section__content--reports">
                            <h2>
                                <FormattedMessage
                                    id="home.subTitle4"
                                    defaultMessage="Voir les rapports de campagne mensuels"
                                />
                            </h2>
                            <p>
                                <FormattedMessage
                                    id="home.text6"
                                    defaultMessage="Rapport montrant les statistiques mensuelles et annuelles sur les dépistages actifs et passifs de la THA, les statistiques comprenant le nombre de cas suspects confirmés, la PTR, la PTE et plus encore."
                                />
                            </p>
                            {userHasPermission(
                                'iaso_trypelim_stats_reports',
                                currentUser,
                            ) && (
                                <a
                                    href="/old-trypelim-dashboard/stats/reports/"
                                    className="button--bright"
                                >
                                    <FormattedMessage
                                        id="home.monthlyReportLink"
                                        defaultMessage="Rapports mensuels"
                                    />
                                    <i className="fa fa-arrow-right icon--right" />
                                </a>
                            )}
                        </div>
                    </section>
                    {userHasPermission(
                        'iaso_trypelim_case_cases',
                        currentUser,
                    ) && (
                        <section className="section--feature--suspect">
                            <div className="section__content--suspect">
                                <h2>
                                    <FormattedMessage
                                        id="home.subTitle5"
                                        defaultMessage="Suivi des cas non confirmés"
                                    />
                                </h2>
                                <p>
                                    <FormattedMessage
                                        id="home.text7"
                                        defaultMessage="Télécharger une liste des suspects non examinés, des examinés non confirmés et des confirmés non traités."
                                    />
                                </p>

                                <a
                                    href="/old-trypelim-dashboard/datas/tests?suspect=true"
                                    className="button--bright"
                                >
                                    <FormattedMessage
                                        id="home.casesLink"
                                        defaultMessage="Aller sur les cas THA"
                                    />
                                    <i className="fa fa-arrow-right icon--right" />
                                </a>
                            </div>
                        </section>
                    )}
                    <section className="section--feature--partners">
                        <h2>
                            <FormattedMessage
                                id="home.subTitle6"
                                defaultMessage="En partenariat avec"
                            />
                        </h2>
                        <div className="section__content--partners">
                            <div className="section__list--partners">
                                <a
                                    href="https://www.itg.be/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={`${STATIC_URL}images/itg_logo.png`}
                                        alt="ITG logo"
                                    />
                                </a>
                            </div>
                        </div>
                    </section>
                </section>
            </Box>
        </>
    );
};

Home.defaultProps = {
    geoZones: null,
    geoProvinces: null,
};

Home.propTypes = {
    fetchHomeDatas: PropTypes.func.isRequired,
    geoZones: PropTypes.object,
    geoProvinces: PropTypes.object,
    barChartDatas: PropTypes.array.isRequired,
    zones: PropTypes.array.isRequired,
};

const MapStateToProps = state => ({
    geoZones: state.home.geoZones,
    geoProvinces: state.home.geoProvinces,
    barChartDatas: state.home.barChartDatas,
    zones: state.home.zones,
});

const MapDispatchToProps = dispatch => ({
    fetchHomeDatas: () => dispatch(homeActions.fetchHomeDatas(dispatch)),
});

export default connect(MapStateToProps, MapDispatchToProps)(Home);
