import { Box } from '@mui/material';
import React from 'react';

export const Registry = () => {
    return (
        <Box>
            <h1>Registry Coming soon</h1>
        </Box>
    );
};
