import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import HomePage from './pages/Home';

import { homeReducer } from './redux/home';
import { useInjectedStore } from '../../../../../../../hat/assets/js/apps/Iaso/redux/useInjectedStore';
import { currentUserReducer } from '../../../redux/currentUserReducer';
import { loadReducer } from '../../../redux/load';

type Props = {
    params: any;
};

export const Home: FunctionComponent<Props> = ({ params }) => {
    const store = useInjectedStore([
        {
            reducerKey: 'home',
            reducer: homeReducer,
        },
        // {
        //     reducerKey: 'load',
        //     reducer: loadReducer,
        // },
        // {
        //     reducerKey: 'currentUser',
        //     reducer: currentUserReducer,
        // },
    ]);
    if (!store) return null;
    return (
        <Provider store={store}>
            <HomePage params={params} />
        </Provider>
    );
};
